@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,600&display=swap');
@import url('https://use.typekit.net/stc1fan.css');

:root {
  --milan-navy: #003542;
  --milan-blue: #0088AB;
  --milan-blue-light: #E5F3F7;
  --milan-secondary-blue: #90d5d5;
  --milan-turquoise: #00A5B4;
  --milan-light-tur: #E4F4F4;
  --fallback-blue: #4DBBC7;
  --milan-red: #F13A1A;
  --milan-white: #f9fbfb;
  --milan-off-white: #EFF7F7;
  --milan-grey: #C2C2C2;
  --milan-light-grey: #efefef;
  --border-radius: 5px;
  --default-transition: all 200ms ease-in-out;
  --tabs-height: 60px;
  --padding-x: 20px;
  --padding-x-sm: 30px;
  --padding-x-lg: 60px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  font-size: 16px;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

/* ******************* Background Color ******************* */
.milan-bg-navy {
  background-color: var(--milan-navy);
}

.milan-bg-primary {
  background-color: var(--milan-blue);
}

.milan-bg-primary-light {
  background-color: var(--milan-blue-light);
}

.milan-bg-secondary {
  background-color: var(--milan-secondary-blue);
}

.milan-bg-red {
  background-color: var(--milan-red);
}

.milan-bg-tur {
  background-color: var(--milan-turquoise);
}

.milan-bg-light-tur {
  background-color: var(--milan-light-tur);
}

.milan-bg-white {
  background-color: var(--milan-white);
}

.milan-bg-light-grey {
  background-color: var(--milan-light-grey);
}

/* ******************* Buttons ******************* */
/* Milan Default Button */
.milan-btn {
  border: none;
  transition: var(--default-transition);
  border-radius: var(--border-radius);
  padding: .5rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  display: inline-block;
  width: auto;
  line-height: 1.875rem;
  cursor: pointer;
  text-decoration: none;
}

.milan-btn:disabled:hover,
.milan-btn:disabled {
  opacity: .7;
  cursor: not-allowed;
  background-color: var(--milan-light-grey);
  color: var(--milan-grey);
}

/* Milan Primary Button */
.milan-primary-btn {
  background-color: var(--milan-blue);
  color: var(--milan-white);
}

.milan-primary-btn:hover {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

/* Milan Secondary Button */
.milan-secondary-btn {
  background-color: var(--milan-secondary-blue);
  color: var(--milan-navy);
}

.milan-secondary-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}

.milan-secondary-btn:disabled {
  background-color: var(--milan-secondary-blue) !important;
  color: var(--milan-navy) !important;
}

/* Milan Navy Button */
.milan-navy-btn {
  background-color: var(--milan-navy);
  color: var(--milan-white);
}

.milan-navy-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-navy);
}

.milan-navy-btn:disabled {
  background-color: var(--milan-navy) !important;
  color: var(--milan-white) !important;
}

.milan-red-btn {
  background-color: var(--milan-red);
  color: var(--milan-white);
}

.milan-red-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-red);
}

.milan-red-btn:disabled {
  background-color: var(--milan-red) !important;
  color: var(--milan-white) !important;
}

/* Milan Grey Button */
.milan-grey-btn {
  background-color: var(--milan-grey);
  color: var(--milan-navy);
}

.milan-grey-btn:hover {
  background-color: rgba(194, 194, 194, .5);
  color: var(--milan-navy);
}

/* Milan Fallback Button */
.milan-fallback-btn {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

.milan-fallback-btn:hover {
  background-color: var(--milan-white);
  color: var(--milan-turquoise);
}

.quote-action-btn {
  color: var(--milan-navy);
}

.quote-action-btn.active {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

.quote-action-btn.primary-bg.active {
  background-color: var(--milan-blue);
}

/* ******************* Text Colors ******************* */
.milan-text-primary {
  color: var(--milan-blue) !important;
}

.milan-text-secondary {
  color: var(--milan-secondary-blue) !important;
}

.milan-text-navy {
  color: var(--milan-navy) !important;
}

.milan-text-tur {
  color: var(--milan-turquoise) !important;
}

.milan-text-red {
  color: var(--milan-red) !important;
}

.milan-text-white {
  color: var(--milan-white) !important;
}

/* Font Weight */
.fw-500 {
  font-weight: 500 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/* ******************* Custom Checkbox ******************* */
.custom-checkbox label {
  cursor: pointer;
  display: block;
  padding-left: 28px;
  margin-bottom: 0px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  font-size: .9rem;
}

.custom-checkbox label input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.custom-checkbox .checkmark {
  background-color: var(--milan-off-white);
  height: 18px;
  left: 0px;
  position: absolute;
  top: 1px;
  width: 18px;
  border-radius: 3px;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  border: solid var(--milan-navy);
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 7px;
  top: 4px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
}

.custom-checkbox input:checked~.checkmark:after {
  display: block;
}

.print-display {
  display: none;
}

@media print {
  @page {
    size: landscape;
  }

  #print-container {
    margin-left: 50px;
  }

  .print-hide {
    display: none !important;
  }

  .print-display {
    display: block;
  }
}


#tx-time-app header {
  min-height: 60px;
}

#tx-time-app header .logo {
  height: 24px;
  max-width: 100%;
}

#tx-time-app header button {
  background-color: var(--milan-light-grey);
  height: 35px;
  font-weight: bold;
  border: none;
  transition: var(--default-transition);
}

#tx-time-app .padding-x {
  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
}

#tx-time-app .pointer {
  cursor: pointer;
}

#tx-time-app .body-areas-tabs {
  overflow-x: auto;
  position: relative;
  z-index: 1;
}

#tx-time-app .body-areas-tabs::before {
  content: '';
  position: absolute;
  width: 100%;
  height: var(--tabs-height);
  right: 0;
  top: 0;
  background-color: var(--milan-light-tur);
  z-index: 0;
}

#tx-time-app .body-areas-tabs .nav-item {
  z-index: 100;
}

#tx-time-app .body-areas-tabs .nav-item .nav-link {
  color: var(--milan-navy);
  width: 114px;
  height: var(--tabs-height);
  text-align: center;
  border: none;
  border-radius: 0;
  padding: 0 13px;
  margin-bottom: 18px;
  background-color: var(--milan-light-tur);
  position: relative;
  font-weight: 600;
}

#tx-time-app .body-areas-tabs .nav-item .nav-link.active {
  color: var(--milan-white);
  background-color: var(--milan-turquoise);
  position: relative;
}

#tx-time-app .body-areas-tabs .nav-item:last-child .nav-link::after {
  content: '';
  position: absolute;
  width: var(--padding-x);
  height: var(--tabs-height);
  left: 100%;
  top: 0;
  background-color: var(--milan-light-tur);
  z-index: 0;
}

#tx-time-app .body-areas-tabs .nav-item .nav-link.active::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  border-left: 57px solid transparent;
  border-right: 57px solid transparent;
  border-top: 18px solid var(--milan-turquoise);
}

#tx-time-app ul.body-areas-tabs {
  border-bottom: none;
}

#tx-time-app ul .sub-area-item {
  height: 55px;
  background-color: var(--milan-light-grey);
  color: #a2a2a2;
  display: block;
  text-align: center;
  border: none;
  font-weight: bold;
  transition: var(--default-transition);
}

#tx-time-app ul .sub-area-item.selected {
  background-color: var(--milan-turquoise);
  color: var(--milan-white);
}

#tx-time-app ul .sub-area-item.disabled {
  background-color: rgba(239, 239, 239, .4);
  color: rgba(162, 162, 162, .4);
  font-weight: 600;
  cursor: not-allowed;
}

#tx-time-app .card-border {
  border: 1px solid var(--milan-turquoise);
  overflow: hidden;
}

#tx-time-app .tt-card .output {
  min-height: 80px;
}

#tx-time-app .tt-card h3 {
  font-family: brandon-grotesque, sans-serif;
}

#tx-time-app #tt-items .container:last-child {
  border-bottom: none !important;
}

@media (min-width: 576px) {
  #tx-time-app .padding-x {
    padding-left: var(--padding-x-sm);
    padding-right: var(--padding-x-sm);
  }

  #tx-time-app ul .sub-area-item {
    min-width: 175px;
  }

  #tx-time-app .body-areas-tabs .nav-item:last-child .nav-link::after {
    width: var(--padding-x-sm);
  }
}

@media (min-width: 992px) {
  #tx-time-app header {
    height: 80px;
  }

  #tx-time-app .padding-x {
    padding-left: var(--padding-x-lg);
    padding-right: var(--padding-x-lg);
  }

  #tx-time-app .body-areas-tabs .nav-item:last-child .nav-link::after {
    width: var(--padding-x-lg);
  }
}

@media (min-width: 1200px) {
  #tx-time-app header .logo {
    height: 24px;
  }

  #tx-time-app .body-areas-tabs .nav-item .nav-link {
    width: 134px;
  }

  #tx-time-app .body-areas-tabs .nav-item .nav-link.active::before {
    border-left: 67px solid transparent;
    border-right: 67px solid transparent;
  }
}